import React, { useEffect, useState } from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HowItWorkBlock from '../../components/Blocks/HowItWorkBlock'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import { DataAutomateHowItWork, DataShowAllListener } from '../../../src/constants.js'
import ShowAllListenerBlock from '../../components/Blocks/ShowAllListenerBlock'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const headerIntroContent = {
     title: 'Automate Your Marketing Data & Analytics',
     description: 'No more inaccurate tracking schemes and waiting on IT.',
     substring: 'Collect marketing & website data faster, more accurately, and with less effort using our library of prebuilt Listeners.',
}
const headerIntroCard = {
     description: `<span>"I guarantee you'll never go back to manual conversion tracking in each destination..."</span>`,
     fName: 'Zachary W. Randall',
     fPosition: 'ListenLayer Founder',
     imageURL: '/images/hero-image.png',
}

const pageName = 'Automate Marketing Data'
const breadcrumbItems = [
     {
          name: 'Platform',
          url: `${'platform'}`,
     },
     {
          name: `${pageName}`,
     },
]

const AutomateMarketingData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="automate-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} />
               <div className="automate-web">
                    <div className="container">
                         <HowItWorkBlock
                              firstContent={DataAutomateHowItWork.firstContent}
                              actions={DataAutomateHowItWork.actions}
                              secondContent={DataAutomateHowItWork.secondContent}
                         />
                    </div>
               </div>
               <SectionBoxReady />
               <section className="automate-listener-intro">
                    <div class="container">
                         <div class="row">
                              <div class="col-lg-10">
                                   <div class="hero-wrapper left ">
                                        <h2>Use Listeners to Automate Data Collection &amp; Conversion Tracking Across All Marketing Platforms</h2>
                                        <div class="sub-text">
                                             <p>
                                                  Place a simple script tag on your website that Listens for activity from services and apps you
                                                  already use. It will generate customizable data layers that automate and enhance your conversion
                                                  tracking and website analytics.
                                             </p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <div className="automate-listener">
                    <div className="container">
                         <ShowAllListenerBlock
                              headlineSearch={DataShowAllListener.headline}
                              subHeadlineSearch={DataShowAllListener.subHeadline}
                              headlineRelated={DataShowAllListener.headlineFeatured}
                              showHeadlineAndBody={DataShowAllListener.showHeadlineAndBody}
                              headlineAndBody={DataShowAllListener.headlineAndBody}
                         />
                    </div>
               </div>
          </Layout>
     )
}

export default AutomateMarketingData
