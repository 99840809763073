import { useStaticQuery, graphql } from "gatsby"

export const useSearchListener = () => {
    const data = useStaticQuery(
        graphql`
        query SearchListenerBlock {
            allWpCptListener {
                nodes {
                    ...WordpressCPTListenerRelatedFields
                }
            }
        }
    `
    )
    return data;
}
