import React, { useState } from 'react'
import { getSlug, logoType } from '../../../helper'
import { useSearchListener } from '../../../hooks/useSearchListener'
import Autosuggest from 'react-autosuggest'

const SearchListenerBlock = ({ headline, subHeadline, column55, listeneNamerCurrent }) => {
    const data = useSearchListener();

    const { allWpCptListener } = data
    const [listenerSelected, setListenerSelected] = useState()
    const [value, setValue] = useState('')
    const listenerSlug = getSlug(allWpCptListener.nodes[0].uri)
    const [suggestions, setSuggestions] = useState([])

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : allWpCptListener.nodes.filter(listener => {
            if (
                listener.cfListener.mainElements.mainName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 &&
                listener.cfListener.mainElements.mainName !== listeneNamerCurrent
            ) return true
            return false
        })
    };

    const getSuggestionValue = suggestion => suggestion.cfListener.mainElements.mainName;

    const onChange = (event, { newValue }) => {
        setValue(newValue)
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const onSuggestionHighlighted = ({ suggestion }) => {
        if (suggestion) {
            const listenerSuggestion = {
                'listenerName': suggestion.cfListener.mainElements.mainName,
                'listenerSlug': `/${listenerSlug}/${suggestion.cfListener.mainElements.slug}/`
            }
            setListenerSelected(listenerSuggestion)
        } else {
            setListenerSelected(listenerSelected)
        }
    }

    const renderSuggestion = suggestion => (
        <div className="result-item">
            {
                logoType(
                    suggestion.cfListener.mainElements.iconFileSvg,
                    suggestion.cfListener.mainElements.iconFileImage,
                    suggestion.cfListener.mainElements.iconUseImageInsteadOfSvg,
                )
            }
            {suggestion.cfListener.mainElements.mainName}
        </div>
    );

    const inputProps = {
        placeholder: "Hubspot, Unbounce, LiveChat...",
        value,
        onChange: onChange,
    };

    const handleClickSearch = () => {
        if (typeof window != 'undefined') {
            window.location.assign(listenerSelected.listenerSlug);
        }
    }

    const onSuggestionSelected = () => {
        if (typeof window != 'undefined') {
            window.location.assign(listenerSelected.listenerSlug)
        }
    }

    return (
        <div className="form-wrapper search">
            <div className="row">
                <div className={`${column55 ? "col-lg-6" : "col-lg-5"}`}>
                    {headline && (
                        <div className="headline-form">
                            <h2>{headline}</h2>
                            {subHeadline && (<p>{subHeadline}</p>)}
                        </div>
                    )}
                </div>
                <div className={`${column55 ? "col-lg-6" : "col-lg-7"}`}>
                    <div className="combobox-wrapper">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            onSuggestionHighlighted={onSuggestionHighlighted}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={onSuggestionSelected}
                            inputProps={inputProps}
                            alwaysRenderSuggestions={false}
                        />
                        <button className="button" onClick={handleClickSearch} disabled={listenerSelected && inputProps.value === listenerSelected.listenerName ? '' : 'disabled'}>Search</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchListenerBlock
